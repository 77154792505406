<ul>
  <li>
    <!-- TODO: リンク横のアイコンを追加 -->
    ・推測され易いパスワードは設定しないでください。<a
      spenLink
      href="https://cdn.classi.jp/manuals/howto_make_password.pdf"
      target="_blank"
      rel="noopener"
      >ウマいパスワードの作り方</a
    >
  </li>
  <li>・半角英字・数字・記号8文字以上、64文字以内で登録ください。</li>
  <li>
    ・使用可能な記号 ! # $ % &amp; , - . / = ? &#64; [ ] ^ _ &#123; &#125; ~
  </li>
  <li>
    ・英字、数字を組み合わせてください。
    <p>（正しい例）　A7#g2%c3</p>
    <p>
      （間違いの例）abcdefgh　12345678　!#$%&&#64;?=　←英字のみ、数字のみ、記号のみは使えません
    </p>
  </li>
  <li>・同一の文字は3つ続けて使用できません。</li>
</ul>
